









































































import Vue from 'vue';
import Message from '../../shared/models/contact/Message.model';
import { email, required } from 'vuelidate/lib/validators';
import { messages } from '@/shared/configs/toasts/toasts.config';

export default Vue.extend({

  name: 'Contact-Us',

  data () {
    return {
      pendingComponentInit: false,
      pendingRequest: false,
      formFeedbackMessage: '',
      messageForm: new Message('επικοινωνία')
    };
  },

  created (): void {
    this.initComponent();
  },

  methods: {
    // ================================================================================
    // Events
    // ================================================================================

    onClearContactForm (): void {
      const form: any = this.$refs.contactForm;
      form.reset();
    },

    async onSendMessage (): Promise<void> {
      if (this.$v.messageForm.$invalid || this.pendingRequest) { return; }
      this.pendingRequest = true;
      try {
        await this.$store.dispatch('contact/sendMessage', this.messageForm);
        this.formFeedbackMessage = messages.contact.success;
        setTimeout(() => {
          this.formFeedbackMessage = '';
          this.onClearContactForm();
        }, 3000);
      } catch (e) {
        this.formFeedbackMessage = messages.common.error;
        setTimeout(() => { this.formFeedbackMessage = ''; }, 3000);
      } finally {
        this.pendingRequest = false;
      }
    },

    // ================================================================================
    // Helpers
    // ================================================================================

    async initComponent (): Promise<void> {
      try {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.pendingComponentInit = true;
        const user = this.$store.getters['auth/user'];
        if (user) { this.messageForm.email = user.email; }
      } catch (e) {
        console.log(e);
      } finally {
        this.pendingComponentInit = false;
      }
    }
  },

  // ================================================================================
  // Validations
  // ================================================================================
  validations: {
    messageForm: {
      name: { required },
      email: { required, email },
      subject: { required },
      message: { required }
    }
  }

});
